import { Component, OnInit } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { SupportModalController } from '../../services/support-modal.controller';
import { NavigationEnd, Router } from '@angular/router';
import { AuthServiceOld } from 'src/authentication/AuthService/auth.service';
import { takeUntil } from 'rxjs/operators';
import { LoggerHelper } from '../../../shared/helper/logger.helper';
import { SupportApiService } from '../../services/support-api.service';
import { TicketCreateInterface } from '../../models/ticket-create.interface';
import { addIcons } from 'ionicons';
import { checkmarkDoneOutline, removeOutline } from 'ionicons/icons';

@Component({
  selector: 'app-support-modal',
  templateUrl: './support-modal.component.html',
  styleUrls: ['./support-modal.component.scss'],
  animations: [
    trigger('enterAnimation', [
      transition(':enter', [style({ transform: 'translate(30px, 30px)', opacity: 0 }), animate('100ms', style({ transform: 'translateY(0)', opacity: 1 }))]),
      transition(':leave', [style({ transform: 'translate(0, 0)', opacity: 1 }), animate('100ms', style({ transform: 'translate(30px, 30px)', opacity: 0 }))]),
    ]),
  ],
})
export class SupportModalComponent implements OnInit {
  name: string;
  email: string;
  phoneNumber: string;
  summary = '';
  description = '';
  sendSessionLogs: boolean;

  ticketId: string;

  isSubmitting = false;
  isSubmitted = false;
  error: string;

  windowLocation = window.location.toString();

  constructor(
    public supportModalController: SupportModalController,
    private router: Router,
    private authService: AuthServiceOld,
    private logHelper: LoggerHelper,
    private supportApi: SupportApiService,
  ) {
    addIcons({ checkmarkDoneOutline, removeOutline });
  }

  ngOnInit(): void {
    this.supportModalController.shouldOpenHandler$.subscribe((isOpen) => {
      if (isOpen) {
        this.router.events.subscribe((evt) => {
          if (evt instanceof NavigationEnd) {
            this.windowLocation = window.location.toString();
          }
        }, takeUntil(this.supportModalController.shouldOpenHandler$));
      }
    });

    this.authService.userProfile$.subscribe((user) => {
      if (user) {
        this.name = user.name;
        this.email = user.email;
        this.phoneNumber = user.phoneNumber;
      }
    });
  }

  resetForm(): void {
    this.isSubmitting = false;
    this.isSubmitted = false;
    this.summary = '';
    this.description = '';
    this.sendSessionLogs = false;
    this.ticketId = '';
    this.error = '';
  }

  async submitTicket(): Promise<void> {
    // Set is Submitting to true
    this.isSubmitting = true;

    const ticketObj: TicketCreateInterface = {
      title: this.summary,
      content: `
      *Name*: ${this.name}
      *Email*: ${this.email}
      *Phone Number*: ${this.phoneNumber}
      *Submitted from*: ${this.windowLocation}

      *Description*: ${this.description}

      ${
        this.sendSessionLogs
          ? '' +
            `
        *ERROR LOGS ATTACHED*
        `
          : '*ERROR LOGS NOT ATTACHED*'
      }
      `,
      phoneNumber: this.phoneNumber,
      attachments: [],
    };

    if (this.sendSessionLogs) {
      const sessionLogJson = JSON.stringify(this.logHelper.errorLogs$.getValue(), null, 2);
      const sessionLogBlob = this.createBlob([sessionLogJson], 'application/json');
      const sessionLogBase64 = await this.convertBlobToBase64(sessionLogBlob);

      ticketObj.attachments.push({
        name: 'error_logs.json',
        data: sessionLogBase64,
      });
    }

    this.supportApi
      .createTicket(ticketObj)
      .toPromise()
      .then((resp) => {
        this.ticketId = resp.response.issueKey;
        this.isSubmitted = true;
        this.isSubmitting = false;
      })
      .catch(() => {
        this.error = 'An error occurred while submitting your request.';
      });
  }

  // Helper methods
  dataToByteArr(data: string): Uint8Array {
    const enc = new TextEncoder();
    return enc.encode(data);
  }

  createBlob(data: any, contentType: 'text/plain' | 'image/png' | 'application/json' | string): any {
    return new Blob(data, { type: contentType });
  }

  convertBlobToBase64(blob: Blob): Promise<string> {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64String = reader.result as string;
        const base64SubString = base64String.substr(base64String.indexOf(',') + 1);
        resolve(base64SubString);
      };
    });
  }
}
