import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { TicketCreateInterface } from '../models/ticket-create.interface';
import { Observable } from 'rxjs/internal/Observable';
import { TicketCreateResponseInterface } from '../models/ticket-create-response.interface';

@Injectable({
  providedIn: 'root',
})
export class SupportApiService {
  private readonly apiUrl: string;

  constructor(private httpClient: HttpClient) {
    this.apiUrl = environment.apiUrl + 'support/';
  }

  createTicket(ticket: TicketCreateInterface): Observable<TicketCreateResponseInterface> {
    return this.httpClient.post<TicketCreateResponseInterface>(this.apiUrl + 'Ticket', ticket);
  }
}
