import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CommonModule } from '@angular/common';
import { CallbackComponent } from 'src/authentication/callback/callback.component';
import { AuthGuard } from 'src/authentication/AuthGuard/auth.guard';
import { ConfirmEsetMoveComponent } from '../modules/eset/components/confirm-eset-move/confirm-eset-move.component';
import { PreloadingService } from './preloading.service';
import { ExclaimerMigrationPageComponent } from 'src/modules/exclaimer/components/exclaimer-migration-page/exclaimer-migration-page.component';

const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },

  {
    path: 'microsoft-robot',
    loadChildren: () => import('../modules/microsoft-robot/microsoft-robot.module').then((m) => m.MicrosoftRobotModule),
    canActivate: [AuthGuard],
    data: { animation: '*' },
  },
  {
    path: 'logs',
    loadChildren: () => import('../deprecated/modules-old/log/log.module').then((m) => m.LogModule),
    canActivate: [AuthGuard],
    data: { animation: '*' },
  },
  {
    path: 'home',
    loadChildren: () => import('../modules/home/home.module').then((m) => m.HomeModule),
    canActivate: [AuthGuard],
    data: { animation: '*' },
  },
  {
    path: 'reports',
    loadChildren: () => import('../modules/reports/reports.module').then((m) => m.ReportsModule),
    canActivate: [AuthGuard],
    data: { animation: '*' },
  },
  {
    path: 'accounts',
    loadChildren: () => import('../deprecated/modules-old/account/account.module').then((m) => m.AccountModule),
    canActivate: [AuthGuard],
  },

  {
    path: 'partners',
    loadChildren: () => import('../modules/partner/partner.module').then((m) => m.PartnerModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'catalogue-products',
    loadChildren: () => import('../modules/catalogue/catalogue.module').then((m) => m.CatalogueModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'customers',
    loadChildren: () => import('../modules/customer/customer.module').then((m) => m.CustomerModule),
    canActivate: [AuthGuard],
  },

  {
    path: 'roles',
    loadChildren: () => import('../deprecated/modules-old/role/role.module').then((m) => m.RoleModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'iaas',
    loadChildren: () => import('../modules/iaas/iaas.module').then((m) => m.IaasModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'nce-migration',
    loadChildren: () => import('../modules/nce-migration/nce-migration.module').then((m) => m.NceMigrationModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'spla',
    loadChildren: () => import('../modules/spla/spla.module').then((m) => m.SplaModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'gdap',
    loadChildren: () => import('../modules/gdap/gdap.module').then((m) => m.GdapModule),
    data: { noAuth: true },
  },
  {
    path: 'transporter',
    loadChildren: () => import('../modules/transporter/transporter.routing').then((m) => m.TRANSPORTER_ROUTES),
  },
  {
    path: 'distributor-admin-panel',
    loadChildren: () => import('../modules/distributer-admin-panel/distributor-admin-panel.routing').then((m) => m.DISTRIBUTOR_ADMIN_PANEL_ROUTES),
    canActivate: [AuthGuard],

  },
  {
    path: 'eset/confirm-eset-account-move',
    component: ConfirmEsetMoveComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'exclaimer/confirm-exclaimer-account-move',
    component: ExclaimerMigrationPageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'callback',
    component: CallbackComponent,
  },
  {
    canActivate: [AuthGuard],
    path: 'activity-log',
    loadChildren: () => import('../modules/activity-log/activity-log.routing').then((m) => m.ACTIVITYLOG_ROUTES),
  },

  { path: '**', redirectTo: '/home' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadingService,
    }),
    CommonModule,
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
