import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SupportModalController {
  // The shouldOpenHandler emits a boolean value to close or open the create-ticket modal. Can be triggered from anywhere
  public shouldOpenHandler$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() {}

  /**
   * Opens all instances of the SupportModalComponent
   */
  open(): void {
    this.shouldOpenHandler$.next(true);
  }

  /**
   * Closes all instances of the SupportModalComponent
   */
  close(): void {
    this.shouldOpenHandler$.next(false);
  }

  /**
   * Opens or closes the SupportModalComponent
   */
  toggle(): void {
    this.shouldOpenHandler$.next(!this.isOpen);
  }

  /**
   * Returns a boolean indicating if the modal is open, or not
   */
  get isOpen(): boolean {
    return this.shouldOpenHandler$.getValue();
  }
}
