import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthServiceOld } from 'src/authentication/AuthService/auth.service';
import { environment } from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';
import { take } from 'rxjs/operators';
import Timeout = NodeJS.Timeout;

@Component({
  selector: 'app-callback',
  templateUrl: './callback.component.html',
  styleUrls: ['./callback.component.scss'],
})
export class CallbackComponent implements OnInit, OnDestroy {
  env = environment;

  timer: Timeout;

  showHelp = false;
  error?: string;

  constructor(
    private authService: AuthServiceOld,
    private route: ActivatedRoute,
  ) {}

  ngOnInit() {
    this.route.queryParams.pipe(take(1)).subscribe((qp) => {
      if (qp?.error && qp.error === 'access_denied') {
        this.error = qp.error_description;
      } else {
        this.authService.handleAuthCallback();
        this.showTimeout();
      }
    });
  }

  showTimeout() {
    this.timer = setTimeout(() => {
      this.showHelp = true;
    }, 4000);
  }

  ngOnDestroy(): void {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  }

  logout() {
    this.authService.logout();
  }
}
