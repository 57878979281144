<div class="ca-container">
  <div class="ca-main" *ngIf="error === undefined">
    <div>
      <ion-spinner style="transform: scale(2.8)" color="primary" name="crescent"></ion-spinner>
    </div>

    <div style="height: 4rem"></div>

    <div style="font-size: 13px">
      <h1>Logging in...</h1>
      <div *ngIf="showHelp" style="line-height: 0.8rem">
        <p style="margin-bottom: 0">
          If you do not get redirected automatically,
          <a href="/">Click Here!</a>
        </p>
        <p style="margin-bottom: 0">If you end up stuck on this page, please <b>check</b> your <b>time settings</b>.</p>
      </div>
    </div>
  </div>

  <div class="ca-main" *ngIf="error !== undefined">
    <div style="font-size: 13px">
      <h1>Error logging in</h1>
      <div style="line-height: 0.8rem">
        <p class="primary-color" style="margin-bottom: 0">
          {{ error }}
        </p>
        <p>
          <ion-button size="big" fill="outline" color="primary" (click)="logout()" style="margin-left: -10px; margin-top: 10px; text-transform: none">Try again</ion-button>
        </p>
      </div>
    </div>
  </div>
</div>
