import langDA from '@angular/common/locales/da';
import langENDK from '@angular/common/locales/en-DK';
import { environment } from 'src/environments/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { AddHeaderInterceptor } from 'src/authentication/http.interceptor';
import { DatePipe, registerLocaleData } from '@angular/common';
import { fancyAnimation } from './route-animations';
import { LoggerHelper } from '../modules/shared/helper/logger.helper';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.modules';
import { ToastrModule } from 'ngx-toastr';
import { NgxsModule } from '@ngxs/store';
import { AppComponent } from './app.component';
import { CallbackComponent } from 'src/authentication/callback/callback.component';
import { PartnerState } from 'src/modules/partner/state/partner.state';
import { SharedState } from 'src/modules/shared/state/shared.state';
import { CatalogueState } from 'src/modules/catalogue/state/catalogue.state';
import { FormsModule } from '@angular/forms';
import { SupportModalModule } from '../modules/support-modal/support-modal.module';
import { NG_SCROLLBAR_OPTIONS, NgScrollbarModule } from 'ngx-scrollbar';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
  IonApp,
  IonAvatar,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonMenu,
  IonMenuButton,
  IonMenuToggle,
  IonRouterOutlet,
  IonRow,
  IonSkeletonText,
  IonSplitPane,
  IonToggle,
  IonToolbar,
  provideIonicAngular,
} from '@ionic/angular/standalone';

registerLocaleData(langDA, 'da');
registerLocaleData(langENDK, 'en-DK');

@NgModule({
  declarations: [AppComponent, CallbackComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NgxsLoggerPluginModule.forRoot({ disabled: environment.production }),
    NgxsModule.forRoot([PartnerState, CatalogueState, SharedState], {
      developmentMode: !environment.production,
    }),
    //IonicModule.forRoot({ mode: "md", navAnimation: fancyAnimation }), <- deprecated see provideIonicAngular in providers
    ToastrModule.forRoot({ positionClass: 'toast-bottom-right', maxOpened: 3 }),
    FormsModule,
    SupportModalModule,
    NgScrollbarModule,
    FontAwesomeModule,
    IonToolbar,
    IonHeader,
    IonApp,
    IonButtons,
    IonMenuButton,
    IonRow,
    IonCol,
    IonSplitPane,
    IonMenu,
    IonList,
    IonItem,
    IonAvatar,
    IonSkeletonText,
    IonLabel,
    IonContent,
    IonMenuToggle,
    IonButton,
    IonIcon,
    IonRouterOutlet,
    IonToggle,
  ],
  providers: [
    NgxsModule,
    DatePipe,
    LoggerHelper,
    { provide: LOCALE_ID, useValue: 'en-DK' },
    { provide: HTTP_INTERCEPTORS, useClass: AddHeaderInterceptor, multi: true },
    {
      provide: NG_SCROLLBAR_OPTIONS,
      useValue: {
        visibility: 'hover',
      },
    },
    provideIonicAngular({ navAnimation: fancyAnimation, mode: 'md' }),
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule {}
