import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SupportModalComponent } from './components/support-modal/support-modal.component';

import { FormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCheckbox,
  IonCol,
  IonFabButton,
  IonGrid,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonRow,
  IonSpinner,
  IonText,
  IonTextarea,
} from '@ionic/angular/standalone';

@NgModule({
  declarations: [SupportModalComponent],
  exports: [SupportModalComponent],
  imports: [
    CommonModule,
    FormsModule,
    FontAwesomeModule,
    IonText,
    IonButton,
    IonCol,
    IonRow,
    IonGrid,
    IonIcon,
    IonSpinner,
    IonFabButton,
    IonLabel,
    IonItem,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonInput,
    IonTextarea,
    IonCheckbox,
  ],
})
export class SupportModalModule {}
