<ion-app *ngIf="!showBlankPage">
  <ion-header class="ion-no-border" [translucent]="true">
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-menu-button color="primary"></ion-menu-button>
      </ion-buttons>

      <ion-row class="ion-flex ion-justify-content-space-evenly">
        <ion-col size-xs="0" size-sm="1" size-md="1"></ion-col>
        <ion-col>
          <div class="logo" routerLink="home">
            <img alt="Cloud Factory" *ngIf="!darkMode" class="cf-logo-width" src="../assets/images/Cloud_Factory_COLOR_RGB.svg" />
            <img alt="Cloud Factory" *ngIf="darkMode" class="cf-logo-width" src="../assets/images/Cloud_Factory_WHITE _RGB.svg" />
          </div>
        </ion-col>

        <ion-col size-xs="0.5" size-sm="1" size-md="1" style="min-width: 48px; min-height: 48px" class="ion-flex ion-justify-content-end">
          <div onclick="rnw('show')" class="release-notes" tooltip="Release Notes">
            <a class="rn-badge">
              <fa-icon [icon]="['fas', 'bell']"></fa-icon>
            </a>
          </div>
        </ion-col>
      </ion-row>
    </ion-toolbar>
  </ion-header>

  <ion-split-pane class="content-margin" contentId="main-content">
    <ion-menu contentId="main-content" type="overlay" style="border-right: none; width: auto">
      <ion-list style="margin: 0% 7%" lines="none" *ngIf="!(authService.Partner | async) && !noAuth">
        <ion-item style="margin-bottom: 20px; margin-top: 14px">
          <ion-avatar style="width: 25px; height: 25px; margin-left: 15px; margin-right: 30px" slot="start">
            <ion-skeleton-text animated></ion-skeleton-text>
          </ion-avatar>
          <ion-label>
            <h3>
              <ion-skeleton-text animated style="width: 50%; border-radius: 0.85rem"></ion-skeleton-text>
            </h3>
          </ion-label>
        </ion-item>

        <ion-item style="margin: 20px 0px">
          <ion-avatar style="width: 25px; height: 25px; margin-left: 15px; margin-right: 30px" slot="start">
            <ion-skeleton-text animated></ion-skeleton-text>
          </ion-avatar>
          <ion-label>
            <h3>
              <ion-skeleton-text animated style="width: 50%; border-radius: 0.85rem"></ion-skeleton-text>
            </h3>
          </ion-label>
        </ion-item>

        <ion-item style="margin: 20px 0px">
          <ion-avatar style="width: 25px; height: 25px; margin-left: 15px; margin-right: 30px" slot="start">
            <ion-skeleton-text animated></ion-skeleton-text>
          </ion-avatar>
          <ion-label>
            <h3>
              <ion-skeleton-text animated style="width: 50%; border-radius: 0.85rem"></ion-skeleton-text>
            </h3>
          </ion-label>
        </ion-item>

        <ion-item style="margin: 20px 0px">
          <ion-avatar style="width: 25px; height: 25px; margin-left: 15px; margin-right: 30px" slot="start">
            <ion-skeleton-text animated></ion-skeleton-text>
          </ion-avatar>
          <ion-label>
            <h3>
              <ion-skeleton-text animated style="width: 50%; border-radius: 0.85rem"></ion-skeleton-text>
            </h3>
          </ion-label>
        </ion-item>

        <ion-item style="margin: 20px 0px">
          <ion-avatar style="width: 25px; height: 25px; margin-left: 15px; margin-right: 30px" slot="start">
            <ion-skeleton-text animated></ion-skeleton-text>
          </ion-avatar>
          <ion-label>
            <h3>
              <ion-skeleton-text animated style="width: 50%; border-radius: 0.85rem"></ion-skeleton-text>
            </h3>
          </ion-label>
        </ion-item>

        <ion-item style="margin: 20px 0px">
          <ion-avatar style="width: 25px; height: 25px; margin-left: 15px; margin-right: 30px" slot="start">
            <ion-skeleton-text animated></ion-skeleton-text>
          </ion-avatar>
          <ion-label>
            <h3>
              <ion-skeleton-text animated style="width: 50%; border-radius: 0.85rem"></ion-skeleton-text>
            </h3>
          </ion-label>
        </ion-item>

        <ion-item style="margin: 20px 0px">
          <ion-avatar style="width: 25px; height: 25px; margin-left: 15px; margin-right: 30px" slot="start">
            <ion-skeleton-text animated></ion-skeleton-text>
          </ion-avatar>
          <ion-label>
            <h3>
              <ion-skeleton-text animated style="width: 50%; border-radius: 0.85rem"></ion-skeleton-text>
            </h3>
          </ion-label>
        </ion-item>
      </ion-list>

      <ion-content>
        <ng-scrollbar>
          <div style="overflow-y: scroll" scrollViewport>
            <ion-list *ngIf="authService.Partner | async" id="inbox-list">
              <ion-menu-toggle auto-hide="false">
                <ion-item class="menu-tab" routerLink="home" (click)="navigateTo('/home')" lines="none" routerLinkActive="selected">
                  <ion-span class="fa-icon">
                    <fa-icon [icon]="['fas', 'house']" size="lg"></fa-icon>
                  </ion-span>
                  <ion-label>Home</ion-label>
                </ion-item>

                <ion-item class="menu-tab" routerLink="customers" (click)="navigateTo('/customers')" lines="none" routerLinkActive="selected">
                  <ion-span class="fa-icon">
                    <fa-icon [icon]="['fas', 'users']" size="lg"></fa-icon>
                  </ion-span>
                  <ion-label>Customers</ion-label>
                </ion-item>

                <ion-item class="menu-tab" routerLink="catalogue-products" (click)="navigateTo('/catalogue-products')" lines="none" routerLinkActive="selected">
                  <ion-span class="fa-icon">
                    <fa-icon [icon]="['fas', 'clouds']" size="lg"></fa-icon>
                  </ion-span>
                  <ion-label>Products</ion-label>
                </ion-item>

                <ion-item
                  *ngIf="authService.hasPermissionObservable(permissionNode.infrastructureManagement) | async"
                  class="menu-tab"
                  routerLink="/iaas/projects"
                  (click)="navigateTo('/iaas/projects')"
                  lines="none"
                  routerLinkActive="selected">
                  <ion-span class="fa-icon">
                    <fa-icon [icon]="['fas', 'server']" size="lg"></fa-icon>
                  </ion-span>
                  <ion-label>IaaS</ion-label>
                </ion-item>

                <ion-item class="menu-tab" routerLink="gdap" (click)="navigateTo('gdap')" lines="none" routerLinkActive="selected">
                  <ion-span class="fa-icon">
                    <fa-icon [icon]="['far', 'bolt']" size="lg"></fa-icon>
                  </ion-span>
                  <ion-label>Microsoft GDAP</ion-label>
                </ion-item>

                <ion-item class="menu-tab" routerLink="microsoft-robot" (click)="navigateTo('microsoft-robot')" lines="none" routerLinkActive="selected">
                  <ion-span class="fa-icon">
                    <fa-icon [icon]="['fas', 'robot']" size="lg"></fa-icon>
                  </ion-span>
                  <ion-label>Microsoft Robot</ion-label>
                </ion-item>

                <ion-item class="menu-tab" routerLink="reports/microsoft/schedules" (click)="navigateTo('reports/microsoft/schedules')" lines="none" routerLinkActive="selected">
                  <ion-span class="fa-icon">
                    <fa-icon [icon]="['fas', 'file']" size="lg"></fa-icon>
                  </ion-span>
                  <ion-label>Microsoft Reports</ion-label>
                </ion-item>

                <ion-item
                  *ngIf="(authService.hasPermissionObservable(permissionNode.isAdmin) | async) || (authService.hasPermissionObservable(permissionNode.SPLAManagement) | async)"
                  class="menu-tab"
                  [routerLink]="['spla', (partner$ | async)?.id, 'current']"
                  lines="none"
                  routerLinkActive="selected">
                  <ion-span class="fa-icon">
                    <fa-icon [icon]="['fas', 'files']" size="lg"></fa-icon>
                  </ion-span>
                  <ion-label>Microsoft SPLA</ion-label>
                </ion-item>

                <ion-item
                  *ngIf="authService.hasPermissionObservable(permissionNode.ActivityLog) | async"
                  class="menu-tab"
                  routerLink="activity-log"
                  lines="none"
                  routerLinkActive="selected">
                  <ion-span class="fa-icon">
                    <fa-icon [icon]="['fas', 'file-magnifying-glass']" size="lg"></fa-icon>
                  </ion-span>
                  <ion-label>Activity Log</ion-label>
                </ion-item>
                <ion-item
                  *ngIf="authService.hasPermissionObservable(permissionNode.MicrosoftTransferRead) | async"
                  class="menu-tab"
                  (click)="enterTransporterOverview()"
                  lines="none"
                  routerLinkActive="selected">
                  <ion-span class="fa-icon">
                    <fa-icon [icon]="['fas', 'truck']" size="lg"></fa-icon>
                  </ion-span>
                  <ion-label>Transporter</ion-label>
                </ion-item>

                <ion-item
                  *ngIf="
                    (authService.hasPermissionObservable(permissionNode.isAdmin) | async) || (authService.hasPermissionObservable(permissionNode.partnerManageOwnLogins) | async)
                  "
                  class="menu-tab"
                  routerLink="accounts"
                  (click)="navigateTo('accounts')"
                  lines="none"
                  routerLinkActive="selected">
                  <ion-span class="fa-icon">
                    <fa-icon [icon]="['fas', 'user-shield']" size="lg"></fa-icon>
                  </ion-span>
                  <ion-label>Accounts</ion-label>
                </ion-item>

                <ion-item
                  *ngIf="authService.hasPermissionObservable(permissionNode.PowerBIReports) | async"
                  class="menu-tab"
                  href="https://app.powerbi.com/Redirect?action=OpenApp&appId=98fb4a2b-56b4-43f8-98bb-0150ce21a88c&ctid=bac01037-8a84-4084-93dc-1475ccc9414c"
                  target="_blank"
                  lines="none"
                  routerLinkActive="selected">
                  <ion-span class="fa-icon">
                    <fa-icon [icon]="['fas', 'chart-pie']" size="lg"></fa-icon>
                  </ion-span>
                  <ion-label>Insights</ion-label>
                </ion-item>

                <ion-item
                  *ngIf="authService.hasPermission(permissionNode.isPartner)"
                  class="menu-tab"
                  href="https://status.cloudfactory.dk/"
                  target="_blank"
                  lines="none"
                  routerLinkActive="selected">
                  <ion-span class="fa-icon">
                    <fa-icon [icon]="['fas', 'heart-pulse']" size="lg"></fa-icon>
                  </ion-span>
                  <ion-label>Service status</ion-label>
                </ion-item>

                <ion-item class="menu-tab" href="https://support.cloudfactory.dk/" target="_blank" lines="none" routerLinkActive="selected">
                  <ion-span class="fa-icon">
                    <fa-icon [icon]="['fas', 'headset']" size="lg"></fa-icon>
                  </ion-span>
                  <ion-label>Support</ion-label>
                </ion-item>

                <ion-item
                  *ngIf="authService.hasPermissionObservable(permissionNode.isAdmin) | async"
                  class="menu-tab"
                  routerLink="partners"
                  (click)="navigateTo('partners')"
                  lines="none"
                  routerLinkActive="selected">
                  <ion-span class="fa-icon">
                    <fa-icon [icon]="['fas', 'rocket-launch']" size="lg"></fa-icon>
                  </ion-span>
                  <ion-label>Partners</ion-label>
                </ion-item>

                <ion-item
                  *ngIf="authService.hasPermissionObservable(permissionNode.developer) | async"
                  class="menu-tab"
                  routerLink="roles"
                  (click)="navigateTo('roles')"
                  lines="none"
                  routerLinkActive="selected">
                  <ion-span class="fa-icon">
                    <fa-icon [icon]="['fas', 'lock-keyhole']" size="lg"></fa-icon>
                  </ion-span>
                  <ion-label>Roles</ion-label>
                </ion-item>
              </ion-menu-toggle>
            </ion-list>

            <ion-list *ngIf="noAuth && !(authService.Partner | async)">
              <ion-item class="menu-tab" lines="none" disabled>
                <ion-span class="fa-icon">
                  <fa-icon [icon]="['fas', 'house']" size="lg"></fa-icon>
                </ion-span>
                <ion-label>Home</ion-label>
              </ion-item>

              <ion-item class="menu-tab" lines="none" disabled>
                <ion-span class="fa-icon">
                  <fa-icon [icon]="['fas', 'clouds']" size="lg"></fa-icon>
                </ion-span>
                <ion-label>Products</ion-label>
              </ion-item>

              <ion-item disabled class="menu-tab" lines="none">
                <ion-span class="fa-icon">
                  <fa-icon [icon]="['fas', 'server']" size="lg"></fa-icon>
                </ion-span>
                <ion-label>IaaS</ion-label>
              </ion-item>

              <ion-item class="menu-tab" lines="none" disabled>
                <ion-span class="fa-icon">
                  <fa-icon [icon]="['fas', 'robot']" size="lg"></fa-icon>
                </ion-span>
                <ion-label>Microsoft Robot</ion-label>
              </ion-item>

              <ion-item class="menu-tab" lines="none" disabled>
                <ion-span class="fa-icon">
                  <fa-icon [icon]="['fas', 'file']" size="lg"></fa-icon>
                </ion-span>
                <ion-label>Microsoft Reports</ion-label>
              </ion-item>

              <ion-item class="menu-tab" routerLink="gdap" (click)="navigateTo('gdap')" lines="none" routerLinkActive="selected">
                <ion-span class="fa-icon">
                  <fa-icon [icon]="['far', 'bolt']" size="lg"></fa-icon>
                </ion-span>
                <ion-label>Microsoft GDAP</ion-label>
              </ion-item>
            </ion-list>

            <br />
            <ion-list class="ion-flex ion-align-items-center">
              <ion-item class="menu-tab" lines="none">
                <fa-icon slot="start" [icon]="['fas', 'moon']" size="lg"></fa-icon>
                <ion-label>Go Dark</ion-label>
              </ion-item>
              <ion-toggle id="themeToggle" (ionChange)="toggleDarkMode()" [ngModel]="darkMode" slot="end"></ion-toggle>
            </ion-list>
          </div>
        </ng-scrollbar>
      </ion-content>

      <ion-row class="account" *ngIf="!noAuth || (authService.Partner | async)">
        <ion-col size="3">
          <ion-avatar class="user-icon">{{ getInitials((authService.userProfile$ | async)?.given_name) }}</ion-avatar>
        </ion-col>

        <ion-col>
          <div (click)="enterPartnerOverview()" style="outline: none; cursor: pointer">
            <div class="user-name">
              {{ (authService.userProfile$ | async)?.given_name }}
              {{ (authService.userProfile$ | async)?.family_name }}
            </div>
            <div class="user-partner">
              {{ (partner$ | async)?.name || 'Loading . .' }}
            </div>
          </div>

          <ion-button size="small" fill="clear" color="danger" (click)="logoutModal()" style="margin-left: -10px; margin-top: 10px; text-transform: none">
            Logout &nbsp;
            <ion-icon slot="end" name="log-out" style="font-size: 12px"></ion-icon>
          </ion-button>
        </ion-col>
      </ion-row>

      <ion-row class="account" *ngIf="noAuth && !(authService.Partner | async)">
        <ion-col size="3">
          <ion-avatar class="user-icon">CF</ion-avatar>
        </ion-col>

        <ion-col>
          <ion-button size="small" fill="clear" color="primary" (click)="authService.logout()">
            Login &nbsp;
            <ion-icon slot="end" name="log-out" style="font-size: 12px"></ion-icon>
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-menu>
    <ion-router-outlet id="main-content"></ion-router-outlet>
  </ion-split-pane>

  <app-support-modal></app-support-modal>
</ion-app>

<div *ngIf="showBlankPage">
  <ion-router-outlet></ion-router-outlet>
</div>
