import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export class Log {
  req: any;
  err: any;
  timeStamp?: string = new Date().toLocaleDateString();
}

@Injectable()
export class LoggerHelper {
  public errorLogs$ = new BehaviorSubject<Log[]>([]);
  constructor() {}

  addErrorLog(logErr: Log): void {
    // Search recusively trough the object for a key called adminPassword
    const redacter = new Redacter({
      properties: ['apiKey', 'password', 'phone', 'adminPassword', 'email', 'address', 'street', 'postalcode', 'name'],
      text: '*********',
    });

    const redactedLogs = redacter.redact(logErr);

    const currentLogs = this.errorLogs$.getValue();
    this.errorLogs$.next([...currentLogs, redactedLogs]);
  }
}

class Redacter {
  options: any;
  properties: any;
  text: string;
  constructor(options) {
    this.options = options || {};
    this.properties = [];
    this.text = '[REDACTED]';

    this.addProperties(this.options.properties);
    this.setText(this.options.text);
  }

  addProperties(properties) {
    if (!properties || !Array.isArray(properties)) {
      return this;
    }

    for (const p of properties) {
      if (this.properties.includes(p)) continue;

      this.properties.push(p);
    }

    return this;
  }

  isObject(property) {
    return typeof property === 'object' && !Array.isArray(property);
  }

  setText(text) {
    if (typeof text !== 'string') return this;

    this.text = text;
    return this;
  }

  redact(json) {
    const isObject = this.isObject(json);

    if (!isObject && !Array.isArray(json)) {
      throw new TypeError('A valid JSON object must be specified');
    }

    const redacted = JSON.parse(JSON.stringify(json));

    for (const prop in redacted) {
      if (this.properties.includes(prop)) {
        redacted[prop] = this.text;
      }

      if (Array.isArray(redacted[prop])) {
        redacted[prop].forEach((value, index) => {
          if (this.isObject(value)) {
            redacted[prop][index] = this.redact(value);
          }
        });
      } else if (this.isObject(redacted[prop])) {
        redacted[prop] = this.redact(redacted[prop]);
      }
    }

    return redacted;
  }
}
