import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { AuthServiceOld, PermissionNode } from 'src/authentication/AuthService/auth.service';
import { AccountService } from 'src/deprecated/services-old/account/account.service';
import { getInitials } from '../modules/shared/helper/getInitials.helper';
import { Select, Store } from '@ngxs/store';
import { PartnerState } from 'src/modules/partner/state/partner.state';
import { Observable } from 'rxjs';
import { IPartner } from 'src/modules/partner/models/partner.model';
import { AlertController, NavController } from '@ionic/angular/standalone';
import { ActivatedRoute, ActivationStart, NavigationStart, Router } from '@angular/router';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faCog, fas } from '@fortawesome/pro-solid-svg-icons';
import { far } from '@fortawesome/pro-regular-svg-icons';
import { fad } from '@fortawesome/pro-duotone-svg-icons';
import { faDropbox, faWindows } from '@fortawesome/free-brands-svg-icons';
import { faAcronis, faAzure, faDropboxSign, faEset, faExclaimer, faKeepit } from '../assets/fa-custom-icons/fa-custom-icons';
import { faCalendarCheck, faMessageCheck, faEllipsisVertical } from '@fortawesome/pro-thin-svg-icons';
import {
  faArrowRotateRight,
  faArrowUpRightFromSquare,
  faBolt,
  faCalendar,
  faCircleCheck,
  faCircleInfo,
  faCircleUser,
  faExclamationCircle,
  faPartyHorn,
  faRobot,
  faSidebarFlip,
} from '@fortawesome/pro-light-svg-icons';
import { addIcons } from 'ionicons';
import { logOut } from 'ionicons/icons';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  @Select(PartnerState.getAuthenticatedPartner) partner$: Observable<IPartner>;

  public darkMode = true;
  public permissionNode = PermissionNode;
  public showBlankPage = false;
  /**
   * This property reflects if the route it self has the noAuth property set, meaning that this route is accessible without
   * the user needs to be authenticated.
   */
  noAuth: boolean;

  constructor(
    private library: FaIconLibrary,
    public authService: AuthServiceOld,
    public alertController: AlertController,
    public accountService: AccountService,
    private router: Router,
    private store: Store,
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute,
    private navController: NavController,
  ) {
    addIcons({ logOut });
    library.addIconPacks(fas, far, fad);
    library.addIcons(
      faCog,
      //fontawesome Light
      faCircleInfo,
      faPartyHorn,
      faCircleUser,
      faBolt,
      faCircleCheck,
      faRobot,
      faExclamationCircle,
      faCalendar,
      faArrowRotateRight,
      faArrowUpRightFromSquare,
      faSidebarFlip,
      //fontawesome thin
      faCalendarCheck,
      faMessageCheck,
      faEllipsisVertical,
      //fontawesome brand
      faWindows,
      faDropbox,
      //fontawesome CF pack
      faDropboxSign as any,
      faAcronis as any,
      faAzure as any,
      faEset as any,
      faExclaimer as any,
      faKeepit as any,
    );

    this.checkDarkTheme();

    router.events.forEach((event) => {
      if (event instanceof NavigationStart) {
        this.showBlankPage = event.url === '/eset/confirm-eset-account-move' || event.url === '/exclaimer/confirm-exclaimer-account-move';
      }
      if (event instanceof ActivationStart) {
        this.noAuth = event.snapshot.data?.noAuth;
      }
    });
  }

  ngOnInit() {}

  public checkDarkTheme() {
    const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');

    // First we check for previously set values. Else we use system default
    if (this.previousTheme === 'dark') {
      document.body.classList.add(this.previousTheme);
      this.darkMode = true;
      return;
    } else if (this.previousTheme === 'white') {
      this.darkMode = false;
      return;
    }

    if (prefersDark.matches) {
      document.body.classList.toggle('dark');
      this.darkMode = true;
    } else {
      this.darkMode = false;
    }
  }

  public toggleDarkMode() {
    this.darkMode = !this.darkMode;
    const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');

    if (this.darkMode && prefersDark.matches) {
      this.previousTheme = undefined;
    } else if (!this.darkMode && !prefersDark.matches) {
      this.previousTheme = undefined;
    }

    if (this.darkMode && !prefersDark.matches) {
      this.previousTheme = 'dark';
    } else if (!this.darkMode && prefersDark.matches) {
      this.previousTheme = 'white';
    }

    document.body.classList.toggle('dark');
  }

  set previousTheme(mode) {
    localStorage.setItem('previousTheme', mode);
  }

  get previousTheme(): 'dark' | 'white' | undefined {
    return localStorage.getItem('previousTheme') as any;
  }

  public async logoutModal() {
    const alert = await this.alertController.create({
      cssClass: 'custom-alert',
      mode: 'ios',
      header: 'Logout',
      message: 'Are you sure you want to log out?',
      buttons: [
        {
          text: 'Confirm',
          cssClass: 'alert-button-confirm',
          handler: () => {
            this.authService.logout();
          },
        },
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'alert-button-cancel',
        },
      ],
    });

    await alert.present();
  }

  getInitials(string) {
    return getInitials(string);
  }

  public enterPartnerOverview() {
    const partnerId: IPartner['id'] = this.store.selectSnapshot(PartnerState.getAuthenticatedPartner).id;

    this.router.navigate(['/partners/partner-overview/' + partnerId]);
  }
  enterTransporterOverview() {
    const partnerId: IPartner['id'] = this.store.selectSnapshot(PartnerState.getAuthenticatedPartner).id;

    this.router.navigate(['/transporter/' + partnerId]);
  }

  public navigateTo(url: string) {
    this.navController.navigateRoot([url]);
  }
}
