<ion-fab-button (click)="supportModalController.toggle()" style="position: fixed; bottom: 30px; right: 30px; z-index: 1001">
  <fa-icon style="font-size: 30px; margin-bottom: 2px" [icon]="['fad', 'user-headset']"></fa-icon>
</ion-fab-button>

<ion-card [@enterAnimation] style="width: 400px; height: 680px; position: fixed; bottom: 60px; right: 60px" *ngIf="supportModalController.shouldOpenHandler$ | async">
  <ion-card-header color="primary">
    <ion-label style="color: white; font-weight: bold"> Contact Partner Care </ion-label>
    <ion-button class="minimize-button" (click)="supportModalController.close()">
      <ion-icon name="remove-outline"></ion-icon>
    </ion-button>
  </ion-card-header>
  <ion-card-content [style]="error ? 'display: flex; height: 100%;' : ''">
    <div style="text-align: center" *ngIf="isSubmitting && !isSubmitted && !error">
      <ion-text color="primary" style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%)">
        <h2>Submitting ticket...</h2>
      </ion-text>
      <img src="assets/images/undraw_sleepy_animation.svg" alt="Sleepy Technician Zzz" />
    </div>
    <ion-grid *ngIf="!isSubmitting && !isSubmitted">
      <!-- Name input -->
      <ion-row>
        <ion-col size="12">
          <ion-item>
            <ion-label position="stacked">Name</ion-label>
            <ion-input [(ngModel)]="name" disabled></ion-input>
          </ion-item>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col size="12">
          <ion-item>
            <ion-label position="stacked">Email</ion-label>
            <ion-input [(ngModel)]="email" disabled></ion-input>
          </ion-item>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col size="12">
          <ion-item>
            <ion-label position="stacked">Phone Number</ion-label>
            <ion-input required [(ngModel)]="phoneNumber"></ion-input>
          </ion-item>
        </ion-col>
      </ion-row>

      <!--<ion-row>
        <ion-col size="12">
          <ion-item>
            <ion-label position="stacked">Resource (Link to resource, if any)</ion-label>
            <ion-input [(ngModel)]="windowLocation"></ion-input>
          </ion-item>
        </ion-col>
      </ion-row>-->

      <ion-row>
        <ion-col size="12">
          <ion-item>
            <ion-label position="stacked">Summary (Short Description) </ion-label>
            <ion-input required [(ngModel)]="summary"></ion-input>
          </ion-item>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col size="12">
          <ion-item>
            <ion-label position="stacked">Description (Detailed)</ion-label>
            <ion-textarea required [(ngModel)]="description"></ion-textarea>
          </ion-item>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col size="12" style="justify-content: left">
          <div style="transform: translateX(-13px)">
            <ion-item class="session-logs-item" size="small" lines="none" style="animation: none">
              <ion-label style="line-height: 14px">
                Send session logs
                <br />
                <small style="margin: 0; padding: 0">Helps us to resolve your case faster</small>
              </ion-label>
              <ion-checkbox [(ngModel)]="sendSessionLogs" style="margin: 0 1rem" mode="ios" slot="start"></ion-checkbox>
            </ion-item>
          </div>
        </ion-col>
      </ion-row>

      <!--<ion-row>
        <ion-col>
          <ion-item lines="none">
            <ion-button color="primary" style="width: 100%; max-width: 300px">
              Submit
            </ion-button>
          </ion-item>
        </ion-col>
      </ion-row>-->
    </ion-grid>
    <div class="card-actions-footer">
      <ion-fab-button style="float: right" (click)="submitTicket()" [disabled]="isSubmitting || summary?.length === 0 || description?.length === 0" *ngIf="!isSubmitted && !error">
        <ion-spinner *ngIf="isSubmitting && !isSubmitted"></ion-spinner>
        <ion-icon *ngIf="!isSubmitting && !isSubmitted" name="checkmark-done-outline"></ion-icon>
      </ion-fab-button>
    </div>

    <!-- Show when request have been submitted -->
    <ion-grid *ngIf="isSubmitted && !error">
      <ion-row style="width: 100%">
        <ion-col size="12" style="display: block">
          <div style="background: var(--ion-color-step-100) !important; border-radius: 30px; padding: 1rem; margin: 0; width: 100%">
            <ion-text style="text-align: center">
              <p>
                Thank you! <br />
                Your request have been received, and assigned the following ID:
                <b>{{ ticketId ? ticketId : '' }}</b>
                <br />
                <br />
                You will receive an email from a support agent shortly
              </p>
            </ion-text>
          </div>
          <div style="padding: 1rem">
            <ion-text style="text-align: center">
              <p>
                If the request are business critical, we are always available at our 24/7 support. <br />
                Phone: <a href="tel:+4553502020">+45 5350 2020</a>
              </p>
            </ion-text>
          </div>
          <div style="text-align: center; padding: 1rem">
            <ion-button class="ion-button" (click)="resetForm()">Reset </ion-button>
          </div>
        </ion-col>
      </ion-row>
    </ion-grid>

    <ion-grid *ngIf="error" style="margin-top: auto; margin-bottom: auto">
      <ion-row style="width: 100%">
        <ion-col size="12" style="display: block">
          <div style="color: white; background: rgba(var(--ion-color-danger-rgb), 0.7) !important; border-radius: 30px; padding: 1rem; margin: 0; width: 100%">
            <ion-text style="text-align: center">
              <p>{{ error }}</p>
            </ion-text>
          </div>
          <div style="padding: 1rem">
            <ion-text style="text-align: center">
              <p>
                If the request are business critical, we are always available at our 24/7 support. <br />
                Phone: <a href="tel:+4553502020">+45 5350 2020</a>
              </p>
            </ion-text>
          </div>
          <div style="text-align: center; padding: 1rem">
            <ion-button class="ion-button" color="danger" (click)="resetForm()">Try again? </ion-button>
          </div>
          <div style="display: flex; margin-top: 2rem">
            <img style="margin: auto" width="300" src="assets/images/undraw_server_down.svg" alt="Request failed" />
          </div>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-card-content>
</ion-card>
