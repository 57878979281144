import { Injectable } from '@angular/core';
import { PreloadingStrategy, Route } from '@angular/router';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PreloadingService implements PreloadingStrategy {
  constructor() {}

  /**
   * Preloads the module if the route has 'preload: true' in the data
   * @param route
   * @param fn
   */
  preload(route: Route, fn: () => Observable<any>): Observable<any> {
    if (route.data != undefined && route.data.preload) {
      return fn();
    }
    return of(null);
  }
}
