import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError, mergeMap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { AuthServiceOld } from './AuthService/auth.service';
import { LoggerHelper } from '../modules/shared/helper/logger.helper';
import { ActivatedRoute } from '@angular/router';
import {isAfter, parseISO} from "date-fns";

@Injectable()
export class AddHeaderInterceptor implements HttpInterceptor {
  constructor(
    private auth: AuthServiceOld,
    private logHelper: LoggerHelper,
    private route: ActivatedRoute,
  ) {}



  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // If the request already has a auth header, just forward the request
    if (req.headers.has('Authorization') || req.headers.has('authorization')) {
      return next.handle(req);
    }
    return this.auth.getTokenSilently$().pipe(
      mergeMap((token) => {
        const decodedToken = token.split('.')[1];
        const expire = new Date(JSON.parse(atob(decodedToken)).exp * 1000)
        const current = new Date();

        if(expire && isAfter(current, expire)){
          this.auth.logout()
        }

        const tokenReq = req.clone({
          setHeaders: { Authorization: `Bearer ${token}` },
        });
        return next.handle(tokenReq);
      }),
      catchError((err) => {
        // Add the error to the logHelper (This stores the log, so we can use it in a support ticket)
        this.logHelper.addErrorLog({ req, err });
        return throwError(err);
      }),
    );
  }
}
